import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { SectionVerticalPadding } from "../../styles/mixins";

export const OverviewContainer = styled.div`
  position: relative;
  background: ${colors.petricite};
  color: ${colors.white};
  .text-header {
    text-align: left;
  }
`;

export const ContentDiv = styled.div`
  ${SectionVerticalPadding};
  position: relative;
  @media (max-width: ${breakpoints.tablet}) {
  }
  @media (max-width: ${breakpoints.mobile}) {
  }
`;

export const TopContainer = styled.div`
  grid-column: 1 / span 12;
`;

export const LeftContainer = styled.div`
  grid-column: 2 / span 5;
  grid-row: 2;

  .text-miniheader {
    font-size: 48px;

    p {
      margin-block-start: 0px;
      max-width: 400px;
    }
  }

  > p {
    font-family: "ProximaNova-Bold";
    color: ${colors.white};
    font-size: 32px;
    line-height: 130%;
    margin: 0;
  }

  @media (max-width: ${breakpoints.laptop}) {
    > p {
      font-size: 24px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;

    .text-miniheader {
      font-size: 28px;
      max-width: 240px;
    }

    > p {
      margin: 0 0 48px 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;

    > p {
      font-size: 18px;
    }
  }
`;

export const RightContainer = styled.div`
  grid-column: 7 / span 5;
  grid-row: 2;

  .text-paragraph {
    > p {
      margin-block-start: 0px;
    }
  }

  > p {
    font-family: "ProximaNova-Regular";
    color: ${colors.white};
    font-size: 18px;
    line-height: 170%;
    margin: 0;
  }

  > p:not(:last-of-type) {
    margin: 0 0 24px 0;
  }

  @media (max-width: ${breakpoints.laptop}) {
    > p {
      font-size: 16px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    grid-row: 3;
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;

    > p {
      font-size: 14px;
      line-height: 150%;
    }
  }
`;

export const backgroundImage = {
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 0,
  width: "100%",
  height: "100%",
} as const;
