import styled from "styled-components";
// import { size } from "../../../../themes/Base/theme";

export const MessageWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  margin: auto;
  visibility: visible;
`;

export const FormErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme?.email_form_font_family || "FF Mark W05"};
  font-size: 12.8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #9d21a9;
  text-align: left;
  text-transform: none;
  padding: 4px 0 0;
  margin: auto;
  background: transparent;

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  .img-container {
    display: block;
    margin-right: 14px;
    width: 16px;
    height: 16px;
    visibility: hidden;

    svg {
      object-fit: contain;
      width: 16px;
      height: 16px;
    }
  }

  .active {
    visibility: visible;
  }
`;
