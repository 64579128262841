import { css } from "styled-components";
import { colors, breakpoints, eases, spacing } from "./variables";

export const PrimaryButtonStyle = () => css`
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding: 8px 32px;
  cursor: pointer;
  position: relative;

  color: ${colors.petricite};
  border: 4px solid ${colors.petricite};
  box-shadow: 4px 4px ${colors.petricite};
  background-size: 250% 100%;
  background-position: 0 0;
  background-image: linear-gradient(30deg, ${colors.gold} 50%, ${colors.white} 51%);
  transition: all 0.2s ease-out, color 0.3s ease-out, background-position 0.5s ${eases.inOut};

  &:hover {
    opacity: 1;
    transform: translate(2px, 2px);
    background-position: 100% 0;
    color: ${colors.petricite};
    box-shadow: 2px 2px ${colors.petricite};
  }

  &:active {
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    box-shadow: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 16px 32px;
  }
`;

export const SectionVerticalPadding = () => css`
  padding: ${spacing.sectionPaddingLaptop} 0;

  @media (max-width: ${breakpoints.tablet}) {
    padding: ${spacing.sectionPaddingTablet} 0;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: ${spacing.sectionPaddingMobile} 0;
  }
`;

export const SectionVerticalPaddingTopDouble = () => css`
  padding: calc(${spacing.sectionPaddingLaptop} * 2) 0 ${spacing.sectionPaddingLaptop};

  @media (max-width: ${breakpoints.tablet}) {
    padding: calc(${spacing.sectionPaddingTablet} * 2.5) 0 ${spacing.sectionPaddingTablet};
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: calc(${spacing.sectionPaddingMobile} * 2) 0 ${spacing.sectionPaddingMobile};
  }
`;
