import { isValid, parse, differenceInYears } from "date-fns";
import { enGB, enUS, ja } from "date-fns/locale";

export const DayMonthYearFormatCountryList = ['fr-fr', 'de-de', 'es-es', 'es-mx', 'it-it', 'pl-pl', 'pt-br', 'ru-ru'];
export const YearMonthDayFormatCountryList = ['ja-jp', 'ko-kr', 'zh-tw'];

// validate date of birth field
export const isBirthdayValid = (formattedBirthday: string, locale: string) => {
  const validFormatRegex = YearMonthDayFormatCountryList.includes(locale) ? /\d{4}\/\d{2}\/\d{2}/ : /\d{2}\/\d{2}\/\d{4}/;

  if (!formattedBirthday || !validFormatRegex.test(formattedBirthday)) {
    return false;
  }

  // NOTE: only `ja` in the `date-fns` library use as YYYY/MM/DD format, so used `ja` for YearMonthDayFormat
  // enUS: MM/DD/YYYY
  // enGB: DD/MM/YYYY
  let dateLocaleFormat =  YearMonthDayFormatCountryList.includes(locale) ? ja : locale === "en-us" ? enUS : enGB;

  let parsedDate = parse(formattedBirthday, "P", new Date(), {
    locale: dateLocaleFormat,
  });

  // is date valid?
  if (!isValid(parsedDate)) {
    return false;
  }

  // is the user at least 13 years old?
  let diff = differenceInYears(new Date(), parsedDate);

  if (!diff || diff < 13) {
    return false;
  }

  return true;
};
