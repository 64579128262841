import styled from "styled-components";
import { colors, breakpoints, zIndices } from "../../styles/variables";

export const ChampionSection = styled.section`
  position: relative;
  padding: 7vw 0;
  background: center center / cover no-repeat;
  background-attachment: fixed;
  background-color: ${colors.periwinkle};
  overflow: hidden;

  .contentDiv {
    grid-column: 1 / span 12;
  }
  .titleBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;

    .text-header {
      color: ${colors.white};
      position: relative;
    }
  }

  .champDeco {
    width: 100%;
  }

  .champColumns {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    -webkit-justify-content: space-evenly !important;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    .swirlLeft {
      bottom: -200px;
      left: 50%;
      transform: translate(-60%, 0);
    }

    .swirlRight {
      top: 0%;
      right: 50%;
      transform: translate(69%, 0);
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .swirlLeft,
    .swirlRight {
      width: auto;
      height: 700px;
    }

    .swirlLeft {
      height: 800px;
      bottom: -20px;
      left: 0%;
      transform: translate(-28%, 0);
    }

    .swirlRight {
      top: 0%;
      right: 0%;
      transform: translate(30%, 0);
    }

    .contentDiv {
      grid-column: 2 / span 6;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 64px 0;

    .contentDiv {
      grid-column: 1 / span 2;
    }
    .titleBox {
      margin-bottom: 32px;
    }
    .fluteImage {
      width: 100px;
    }
    .champColumns {
      flex-direction: column;
      align-items: center;
      .champCard {
        width: 100%;
      }
    }
    .swirlRight {
      right: 50%;
      height: 520px;
      transform: translate(50%, 0);
    }
    .swirlLeft {
      height: 420px;
      left: 50%;
      transform: translate(-480px, 0);
    }
  }
`;
