import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import bannerImage from "../../assets/images/banner-image.jpg";

export const StyledDiv = styled.div`
  background: ${colors.gold};
  color: ${colors.petricite};
  position: relative;
  font-family: "ProximaNova-Bold";
  font-size: 0.875rem;
  margin: 0;
  overflow: hidden;
  padding: 0.938rem 1rem 1rem 1rem;
  text-align: center;
  text-transform: uppercase;

  span {
    position: relative;
    z-index: 5;
  }

  a {
    color: ${colors.lime};
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.mobile}) {
    a {
      display: block;
    }
  }

  &.link {
    cursor: pointer;
  }

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: ${colors.black};
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
  }

  &:after {
    right: 10%;
    background-image: url(${bannerImage});
    background-repeat: no-repeat;
    background-position: 90% 42.5%;
    background-size: 100%;
    box-shadow: inset 50px 0px 50px black;
  }

  &:before{
    right: 60%;
    background: ${colors.black};
  }

  &:hover {
    &:before, &:after {
      opacity: 0.2;
    }

    &:after {
      right: 0%;
    }

    &:before {
      right: 50%;
    }
  }
`;
