import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";

export const HeroSection = styled.section`
  width: 100%;
  position: relative;
  background: ${colors.petricite};
  .backgroundImg {
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    height: 100%;
    background: center center / cover no-repeat;

    @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      top: 0;
    }
  }
  .char {
    position: absolute;
    background: center center / contain no-repeat;
    pointer-events: none;
  }
  .topFoliage {
    width: 110%;
    left: -50px;
    top: -48px;
    z-index: ${zIndices.ekko};
  }
  .charSylas {
    position: absolute;
    top: 1px;
    left: 18px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      top: -44px;
    }
  }
  .centerCol {
    position: relative;
    width: 100%;
    top: 4%;
    min-height: 1000px;
    max-width: 1440px;
    padding: 0 105px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .mainLogo {
      width: 45vh;
      max-width: 285px;
      margin: 40px 0px 24px 0px;
    }
    .infoGroup {
      text-align: center;
      width: 597px;
    }
    .available {
      font-size: 20px;
      margin-bottom: 16px;
      color: ${colors.white};
    }
    .text-wrapper {
      display: flex;
      justify-content: center;
    }
    .text-paragraph {
      font-family: "ProximaNova-light";
      text-align: center;
      margin-bottom: 24px;
      max-width: 100%;
      color: ${colors.white};
    }
    .platforms {
      max-width: 430px;
      margin: auto;
      svg {
        display: inline-block;
        width: 35px;
        height: 35px;
        margin: 0 10px;
        position: relative;
      }
      span:nth-child(1),
      span:nth-child(2) {
        svg {
          width: 100px;
        }
      }
      span:nth-child(3) {
        svg {
          width: 70px;
        }
      }
      span:nth-child(4) {
        svg {
          width: 70px;
        }
      }
      path {
        fill: ${colors.white};
        transition: fill 0.3s ease-out;
      }
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .backgroundImg {
      display: none;
    }
    .charSylas {
      display: none;
    }
    .topFoliage {
      display: none;
    }
    .tabletBackgroundImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: center center / cover no-repeat;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    .backgroundGradient {
      height: 100%;
    }
    .centerCol {
      padding: 0 70px;
      .mainLogo {
        width: 360px;
        margin-bottom: 50px;
      }
      .platforms {
        max-width: 295px;
        margin-bottom: 16px;
        svg {
          width: 30px;
          height: 30px;
          margin: 0 6px;
        }
        span:nth-child(1),
        span:nth-child(2) {
          svg {
            width: 80px;
          }
        }
        span:nth-child(3) {
          svg {
            width: 60px;
          }
        }
      }
      .text-paragraph {
        max-width: 100%;
      }
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .mobileBackgroundImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: center center / cover no-repeat;
    }
    .tabletBackgroundImg {
      display: none;
    }
    .backgroundGradient {
      z-index: 1;
    }
    .topFoliage {
      display: none;
    }
    .centerCol {
      padding: 0 24px;
      padding-bottom: 245px;
      .mainLogo {
        width: 70vw;
        max-width: 360px;
        margin-top: 32px;
        margin-bottom: 16px;
      }
      .infoGroup {
        text-align: center;
        width: 300px;
      }
      .available {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 8px;
      }
      .text-paragraph {
        max-width: 300px;
        line-height: 1.5em;
        margin-bottom: 18px;
      }
      .platforms {
        display: none;
      }
    }
  }
  @media (max-height: 700px) {
    .centerCol .platforms {
      display: none;
    }
  }
`;
