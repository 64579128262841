import styled from "styled-components";
import { colors, breakpoints, eases, zIndices } from "../../styles/variables";

export const FeaturesContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.petricite};
  .innerClippedBgnd {
    width: 100%;
    height: 100%;

    /* background-position: center -60px; */

    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 11.5vw),
      89.2% calc(100% - 9.5vw),
      90.5% calc(100% - 16.5vw),
      0 calc(100% - 4vw)
    );
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: calc(140px + 14vw);
  }

  .fixedBg {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;

    .fullBg {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      width: 100%;

      .poster {
        bottom: 15%;
        cursor: pointer;
        left: 50%;
        position: absolute;

        &:hover {
          .poster-img-hover {
            opacity: 1;
            z-index: 1;
          }
        }

        .poster-img {
          position: absolute;
        }

        .poster-img-hover {
          opacity: 0;
          transition: opacity 0.5s ease-out;
        }
      }
    }
  }

  .layoutBox {
    width: 100%;
    grid-column: 1 / span 12;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slideBox {
    width: 100%;
    background-color: ${colors.purpleLight};
    position: relative;
    margin-bottom: 64px;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pagArrow {
      cursor: pointer;
      width: 40px;
      height: 40px;
      &.pagArrowRight {
        transform: scaleX(-1);
      }
      .pagSVG {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ${eases.out};
      }
      .pagArrowPath {
        fill: ${colors.purpleLight};
      }
      .pagArrowHover {
        fill: ${colors.white};
        transform: scale(0);
        transition: transform 0.3s ${eases.out};
      }
      &:hover {
        .pagSVG {
          transform: translateX(-5px);
        }
        .pagArrowHover {
          transform: scale(0.5);
        }
      }
    }
    .pagRow {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      -webkit-justify-content: space-evenly !important;
      flex-grow: 2;
      margin: 0 32px;
    }
    .pagWidget {
      margin-right: 24px;
      width: 46px;
      color: ${colors.white};
      cursor: pointer;

      &:last-of-type {
        margin: 0;
      }
      .pagNum {
        font-family: "ProximaNova-Black";
        font-size: 16px;
        line-height: 1em;
        font-weight: normal;
        transition: all 0.3s ease-out;
      }
      .pagBar {
        position: relative;
        display: block;
        background-color: ${colors.white};
        height: 6px;
        width: 100%;
        margin: 4px 0 0 0;
        overflow: hidden;
        transform: skewX(-25deg);
      }
      .pagProgress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: ${colors.purpleLight};
        transition: width 0.3s ${eases.inOut};
      }

      &.active {
        color: ${colors.purpleLight};
        .pagProgress {
          width: 100%;
        }
      }
      &:hover {
        .pagNum {
          transform: translateY(-3px);
        }
        .pagBar {
          transform: skewX(-25deg) scaleY(1.2);
        }
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .layoutBox {
      grid-column: 2 / span 6;
    }

    .slideBox {
      box-shadow: 8px 8px 0px ${colors.petricite};
    }

    .innerClippedBgnd {
      background-position: center;
      padding-bottom: calc(60px + 14vw);
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .layoutBox {
      grid-column: 1 / span 2;
      margin-bottom: 48px;
    }
    .slideBox {
      margin-bottom: 32px;
    }
    .pagination {
      width: 100%;
      .pagArrow {
        width: 20px;
        height: 20px;
      }
      .pagRow {
        margin: 0 10px;
      }
      .pagWidget {
        width: 30px;
        margin: 0;
      }
    }
  }
`;

export const SVGLightningLines = styled.svg`
  bottom: 0;
  pointer-events: none;
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  z-index: ${zIndices.lightningClip};
  transform: translateY(34%);

  path:nth-child(2) {
    transform: translateY(-10px);
  }

  @media (max-width: ${breakpoints.tablet}) {
    transform: translateY(36%);
  }

  @media (max-width: ${breakpoints.mobile}) {
    transform: translateY(36%);
  }
`;
