import React from "react";
import styled from "styled-components";
import { ContentstackImg } from "@riotgames/wwpub-components";
import { breakpoints, colors } from "../../styles/variables";
import deco from "../../assets/images/champion/Deco.png";

const ChampCard = styled.div`
  position: relative;
  width: 16%;
  min-width: 200px;
  background: ${colors.purpleLight};
  padding: 8px;
  cursor: pointer;
  box-shadow: 8px 8px 0 ${colors.black};
  transition: box-shadow 0.5s ease-out;
  margin-bottom: 30px;

  .champWindow {
    width: 100%;
    padding-top: 180%;
    position: relative;
    overflow: hidden;
    border: 1px ${colors.gold} solid;
  }
  .deco {
    position: absolute;
    left: 0px;
    top: -5px;
    z-index: -1;
    height: 50% !important;
  }
  .champImage {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    transform: scale(1);
    transition: transform 0.7s ease-out;
    background: ${colors.purple};
  }

  .champImage img {
    display: flex;
    flex: 1;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  .text-miniheader {
    color: #fff;
    padding: 24px 0 12px;
    text-align: center;
    text-transform: uppercase;
  }

  &:hover {
    box-shadow: 8px 8px 0 ${colors.black};

    .champImage {
      transform: scale(1.25);
    }
  }

  @media (max-width: 1277px) {
    width: 28%;

    .text-miniheader {
      padding: 12px 0;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 45%;

    .text-miniheader {
      padding: 12px 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 80vw;
    max-width: 300px;

    .champWindow {
      padding-top: 150%;
    }
    .champImage {
      align-items: center;
      img {
        margin: unset;
      }
    }
  }
`;

interface Props {
  imageSrc: string;
  linkHref: string;
}

export default class ButtonText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  openURL = (event: React.MouseEvent): void => {
    window.open(this.props.linkHref, "_new");
  };

  render() {
    return (
      <ChampCard onClick={this.openURL}>
        <div className="champWindow">
          <div className="champImage">
            <img className="deco" src={deco} />
            <ContentstackImg image={{ url: this.props.imageSrc }} />
          </div>
        </div>
        <div className="text-miniheader">{this.props.children}</div>
      </ChampCard>
    );
  }
}
