import * as React from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import RiotBar from "@riotgames/riotbar-react";
import Seo, { SeoData } from "../components/Seo/Seo";
import TopBanner, { TopBannerData } from "../components/TopBanner/TopBanner";
import Hero, { HeroData } from "../components/Hero/Hero";
import Overview, { OverviewData } from "../components/Overview/Overview";
import Purchase, { GameEdition, GameLogo } from "../components/PurchasePage/Purchase";
import Features, { FeaturesData } from "../components/Features/Features";
import Champions, { ChampionsData } from "../components/Champions/Champions";
import FullCarousel, { FullCarouselData } from "../components/FullCarousel/FullCarousel";
import Newsletter, { NewsletterData } from "../components/Newsletter/Newsletter";
import Publisher, { PublisherData } from "../components/Publisher/Publisher";
import HorizontalGallery, { HorizontalGalleryData } from "../components/HorizontalGallery/HorizontalGallery";
import VideoOverlay from "../components/VideoOverlay";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import { StyleSheetManager } from "styled-components";
import griddie from "styled-griddie";

interface PageData {
  home: {
    homeSeo: SeoData;
    topBanner: TopBannerData;
    heroMarquee: HeroData;
    gameOverview: OverviewData;
    gameFeatures: FeaturesData;
    gameChampions: ChampionsData;
    gameLoreCarousel: FullCarouselData;
    gameNewsletter: NewsletterData;
    gameStudioOverview: PublisherData;
    gamesCarousel: HorizontalGalleryData;
  };
  purchase: {
    purchaseSeo: SeoData;
    heading: string;
    launchHeading: string;
    description: string;
    subheading: string;
    purchaseLinksHeading: string;
    gameEditions: GameEdition[];
    gameLogo: GameLogo;
  };
}

interface Props extends PageProps<PageData> {}

const IndexPage: React.FC<Props> = ({ data }) => {
  const [videoOpen, setVideo] = React.useState(false);
  const [eeVideoOpen, seteeVideo] = React.useState(false);

  const {
    homeSeo,
    topBanner,
    heroMarquee,
    gameOverview,
    gameFeatures,
    gameChampions,
    gameLoreCarousel,
    gameNewsletter,
    gameStudioOverview,
    gamesCarousel,
  } = data.home;

  const { purchase } = data;

  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();
  const ytVideoUrl = heroMarquee.videoLink.href;
  const eeVideoUrl = gameFeatures.hiddenVideoEmbed.href;
  const ytVideoCode = ytVideoUrl.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/)?.[1] || "";
  const eeYTVideoCode = eeVideoUrl.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/)?.[1] || "";

  const homeUrl = `https://${siteDomain}/${language}/`;

  // Set the Riotbar environment prop to 'staging' for local development & branch deploys
  // Otherwise leave it unset so that Riotbar will use the prod environment
  const riotbarEnv = process.env.RIOTBAR_ENVIRONMENT === "staging" ? "staging" : undefined;

  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <main>
        <Seo data={homeSeo} url={homeUrl} locale={language} />
        {topBanner?.message && <TopBanner data={topBanner} />}
        <RiotBar product="forge-starblitz" locale={language} environment={riotbarEnv} />
        <Hero
          data={heroMarquee}
          videoOpen={() => {
            setVideo(true);
          }}
        />
        <Overview data={gameOverview} />
        <Features
          data={gameFeatures}
          VideoOpen={() => {
            seteeVideo(true);
          }}
        />
        <Purchase data={purchase} withBackground={false} withBgFade={false} />
        <Champions data={gameChampions} />
        <FullCarousel data={gameLoreCarousel} />
        <Newsletter data={gameNewsletter} locale={language} />
        <Publisher data={gameStudioOverview} />
        <HorizontalGallery data={gamesCarousel} />
        {videoOpen && (
          <VideoOverlay
            ytVideoCode={ytVideoCode}
            callback={() => {
              setVideo(false);
            }}
          />
        )}
        ,
        {eeVideoOpen && (
          <VideoOverlay
            ytVideoCode={eeYTVideoCode}
            callback={() => {
              seteeVideo(false);
            }}
          />
        )}
      </main>
    </StyleSheetManager>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    home: contentstackStarblitzHomePage(locale: { eq: $language }) {
      ...HomeSeoFragment
      ...TopBannerFragment
      ...HeroMarqueeFragment
      ...GameOverviewFragment
      ...GameFeaturesFragment
      ...GameChampionsFragment
      ...FullCarouselFragment
      ...GameNewsletterFragment
      ...GameStudioOverviewFragment
      ...GamesCarouselFragment
    }
    purchase: contentstackMageseekerPurchasePage2(locale: { eq: $language }) {
      ...PurchaseLandingFragment
    }
    ...TranslationQueryFragment
  }
`;
